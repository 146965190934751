<template>
  <v-container>
    <v-row class="mt-5">
      <a class="subtitle-1 font-weight-bold text-back" @click="$router.go(-1)">
        <v-icon class="px-2">mdi-arrow-left</v-icon>Regresar
      </a>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="8" class="content-detail">
        <div class="text-content">
          <h1 class="titulo-oferta my-3">{{ job.title }}</h1>
        </div>
       <v-row>
      <v-col>
        <v-chip class="my-1 text-chip" color="orange" outlined>
          <v-img left src="../assets/icon-ubicacion.svg" class="ma-2"></v-img>
          <h4>{{ job.sede }}</h4>
        </v-chip>
        <v-chip class="ma-2" color="orange" outlined>
          <v-img left src="../assets/icon-modalidad.svg" class="ma-2"></v-img>
          <h4>{{ job.modality }}</h4>
        </v-chip>
        <v-chip class="ma-2" color="orange" outlined>
          <v-img
            left
            src="../assets/icon-tipo-trabajo.svg"
            class="ma-2"
          ></v-img>
          <h4>{{ job.typeWork }}</h4>
        </v-chip>
        <v-chip class="ma-2" color="orange" outlined>
          <v-img left src="../assets/icon-turno.svg" class="ma-2"></v-img>
          <h4>{{ job.turn }}</h4>
        </v-chip>
      </v-col>
       </v-row>
        <div v-html="job.content" class="my-6"></div>
        <v-row>
          <v-col cols="12" sm="6" md="8" class="text-apto">
            <div v-if="job.disability == 'Si'">
              <v-row>
                <div class="mdi mdi-checkbox-marked icon-check ma-1"></div>
                <p class="text-apto ma-1">
                  Apto para personas con discapacidad
                </p>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col>
            <div class="text-content">
        <v-img :src="job.image" height="240" class="grey darken-4 my-5 content-image"></v-img>
        </div>
      </v-col>

    </v-row>
    <div class="d-flex justify-center my-10">
      <v-btn color="info" class="mx-2 px-16 btn-postular" @click="applyFromJob()">
        POSTULAR
      </v-btn>
    </div>
    <v-footer padless>
      <v-row>
        <v-col class="my-8 text-center" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>Konecta Perú</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'offerDetail',
  props: {
    idJob: {
      type: String,
      required: false,
    },
    job: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      content: '',
    };
  },
  methods: {
    applyFromJob() {
      sessionStorage.setItem('jobCategory', this.job.category);
      sessionStorage.setItem('jobId', this.idJob);
      this.$gtag.event('LPB_Bolsa_btn_Postular', {
        event_category: 'LPB_Bolsa_btn_Postular',
        event_label: 'LPB_Bolsa_btn_Postular',
        value: 1,
      });
      this.$router.push({ name: 'selection' });
    },
  },
};
</script>

<style lang="scss" scoped>

.text-content {
  display: flex;
  @media (max-width: 600px) {
    justify-content: center;
    font-size: 10px;
  }
}
.content-detail {
  @media (max-width: 600px) {
 padding: 35px;
}
}
.text-apto {
  @media (max-width: 600px) {
    justify-content: center;
    font-size: 15px;
  }
}
.content-image {
  @media (max-width: 600px) {
 height: 180px;
}
}
.titulo-oferta {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #333333;

  @media (max-width: 600px) {
    justify-content: center;
    font-size: 18px;
  }
}
.icon-check {
  color: orange;
}
h4 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #333333;
}
p {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #333333;
}
span {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #3a82d0;
}
text-apto {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #333333;
}
</style>
