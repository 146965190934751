import { render, staticRenderFns } from "./OfferDetail.vue?vue&type=template&id=65b76cec&scoped=true"
import script from "./OfferDetail.vue?vue&type=script&lang=js"
export * from "./OfferDetail.vue?vue&type=script&lang=js"
import style0 from "./OfferDetail.vue?vue&type=style&index=0&id=65b76cec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b76cec",
  null
  
)

export default component.exports